import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { Z_INDEX_TOP } from '../../utils/sizes.utils';

export const useWidgetFrameStyles = makeStyles<Theme, { isOpen: boolean }>(
	({
		palette: {
			sizes,
			boxShadow,
			colorScheme: { chatWindowHeight, customStyle, chatWindowRightPosition, chatWindowBottomPosition },
			borderRadius,
			transitionConfig: { noAnimationTransition },
		},
	}) => ({
		root: {
			boxSizing: 'border-box',
			position: 'fixed',
			height: chatWindowHeight,
			border: 'none',
			outline: 'none',
			borderRadius: borderRadius.widget,
			overflow: 'hidden',
			zIndex: Z_INDEX_TOP - 1,
			transition: noAnimationTransition ? 'initial' : 'all 0.4s ease',
			boxShadow: boxShadow.angle.xlarge,
			maxHeight: 888,
			width: sizes.size_50,
			right: chatWindowRightPosition,
			bottom: (props) => (props.isOpen ? chatWindowBottomPosition : 0),
			'@media (max-height: 500px)': {
				bottom: '24px !important;',
			},
			...customStyle?.iframe?.notFullScreen,
		},
		fullScreen: {
			zIndex: Z_INDEX_TOP - 1,
			position: 'fixed',
			left: 0,
			top: 0,
			bottom: 0,
			right: 0,
			height: '100%',
			width: '100%',
			transition: noAnimationTransition ? 'initial' : 'bottom 0.15s ease-out, opacity 0.15s ease-out',
			borderRadius: 0,
			border: 'none',
			margin: '0 auto',
			backgroundColor: 'white',
			...customStyle?.iframe?.fullScreen,
		},
		closedWidget: {
			visibility: 'hidden',
			pointerEvents: 'none',
			transition: noAnimationTransition ? 'initial' : 'all 0.2s ease',
			opacity: 0,
			bottom: 0,
			zIndex: -1,
		},
		mobileHidden: {
			display: 'none',
		},
	}),
);
