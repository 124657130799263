import { CSSProperties } from '@material-ui/styles';

import { ColorVariables } from '../../models/app.model';

export interface TransitionConfig {
	noAnimationTransition: boolean;
	transitionInDuration: number;
	transitionOutDuration: number;
}
export interface BorderRadiusConfig {
	widget: number;
	card: number;
	button: number;
}

export type SizesKeys =
	| 'sizeQuarter'
	| 'sizeHalf'
	| 'size_1'
	| 'size_2'
	| 'size_3'
	| 'size_4'
	| 'size_5'
	| 'size_6'
	| 'size_7'
	| 'size_8'
	| 'size_9'
	| 'size_10'
	| 'size_11'
	| 'size_12'
	| 'size_13'
	| 'size_14'
	| 'size_15'
	| 'size_16'
	| 'size_17'
	| 'size_18'
	| 'size_19'
	| 'size_20'
	| 'size_30'
	| 'size_40'
	| 'size_50'
	| 'size_60'
	| 'size_70'
	| 'size_80';

export const sizes: Record<SizesKeys, number> = {
	sizeQuarter: 2,
	sizeHalf: 4,
	size_1: 8,
	size_2: 16,
	size_3: 24,
	size_4: 32,
	size_5: 40,
	size_6: 48,
	size_7: 56,
	size_8: 64,
	size_9: 72,
	size_10: 80,
	size_11: 88,
	size_12: 96,
	size_13: 104,
	size_14: 112,
	size_15: 120,
	size_16: 128,
	size_17: 136,
	size_18: 144,
	size_19: 152,
	size_20: 160,
	size_30: 240,
	size_40: 320,
	size_50: 400,
	size_60: 480,
	size_70: 560,
	size_80: 720,
};

export const getbasicHTMLStyles = (colorVariables: ColorVariables): Record<string, CSSProperties> => ({
	ul: {
		listStylePosition: 'outside',
		listStyleType: 'disc',
		marginLeft: '1.3rem',
		padding: 0,
		color: colorVariables.colorText,
		lineHeight: '1.5rem',
	},
	ol: {
		listStylePosition: 'outside',
		listStyleType: 'decimal',
		marginLeft: '1.5rem',
		padding: 0,
		color: colorVariables.colorText,
		lineHeight: '1.5rem',
	},
	em: {
		fontStyle: 'italic',
	},
	strong: {
		fontWeight: 'bold',
	},
});

export interface GenericStyle {
	fontFamily?: string;
	fontSize?: string;
	fontWeight?: number;
	color?: string;
}

export type GeneralStylesKeys =
	| 'typeTitle1'
	| 'typeTitle2'
	| 'typeTitle3'
	| 'typeHeading1'
	| 'typeHeading2'
	| 'typeSubheading1'
	| 'typeBot1'
	| 'typeInput1'
	| 'typeCaption1'
	| 'typeButton1'
	| 'typeButton1Alt'
	| 'typeChip1'
	| 'typeChip2'
	| 'typeData1'
	| 'typeNoteHeading'
	| 'typeNoteParagraph'
	| 'typeNoteLink'
	| 'typeLink1'
	| 'typeLink2'
	| 'typeSysText1'
	| 'typeSysText2'
	| 'typeSysHeading1'
	| 'typeSysHeading2'
	| 'typeSysLink1'
	| 'typeSysLink2'
	| 'body'
	| 'p';

export type GeneralStyles = Record<GeneralStylesKeys, CSSProperties>;

export const enum ScreenSize {
	LargeViewPort = 'LargeViewPort',
	SmallViewPort = 'SmallViewPort',
}

export interface GlobalTypographyStyles {
	h1: CSSProperties;
	h2: CSSProperties;
	p: CSSProperties;
	body: CSSProperties;
	body1: CSSProperties;
	body2: CSSProperties;
}

export interface WidgetBackground {
	gradient: CSSProperties;
	overlay: CSSProperties;
}

export const getBorderRadiusConfig = (radiusType: string): BorderRadiusConfig => {
	switch (radiusType) {
		case 'Radius_0':
			return {
				widget: 0,
				card: 0,
				button: 0,
			};
		case 'Radius_1':
			return {
				widget: 16,
				card: 8,
				button: 2,
			};
		case 'Radius_2':
			return {
				widget: 32,
				card: 16,
				button: 8,
			};
		case 'Radius_4':
			return {
				widget: 56,
				card: 32,
				button: 24,
			};
		case 'Radius_3':
		default:
			return {
				widget: 40,
				card: 24,
				button: 16,
			};
	}
};
export const DEFAULT_GRADIENT_CONFIG: WidgetBackground = {
	gradient: {
		backgroundColor: 'white',
	},
	overlay: {
		backgroundColor: 'white',
	},
};

export const getWidgetBackground = (
	backgroundType: string,
	primaryColor: string,
	secondaryColor: string,
): WidgetBackground => {
	switch (backgroundType) {
		case 'gradient':
			return {
				gradient: {
					backgroundImage: `linear-gradient(270deg, ${primaryColor} 0%, ${secondaryColor} 100%, ${secondaryColor} 100%)`,
				},
				overlay: {
					backgroundImage:
						'linear-gradient(206deg, rgba(255,255,255,0.88) 0%, rgba(255,255,255,0.96) 26%, #FFFFFF 48%, #FFFFFF 100%)',
				},
			};
		default:
			return DEFAULT_GRADIENT_CONFIG;
	}
};
