import { flow } from 'fp-ts/lib/function';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import { CollapsedWidgetConfigResponseModel } from '../../context/api-service/api-service.model';
import { ConfigContext } from '../../context/config.context';
import { EventsServiceContext } from '../../context/events-service/events-service.context';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { createAnimation } from '../../utils/animations.utils';
import { toAggregatedComponentsEventData } from '../../utils/events.utils';
import { Lazy } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';
import { CollapsedWidget } from './collapsed-widget.component';
import {
	CollapsedWidgetButtonConfig,
	DismissButtonConfig,
	getTooltipConfig,
	MinimizeButtonConfig,
	setWasCollapsedWidgetButtonsComponentShown,
	shouldLoadComponent,
	toDismissButtomConfig,
	toGetCollapsedWidgetConfigParams,
} from './collapsed-widget.model';

interface CollapsedWidgetContainerProps {
	isWidgetOpen: boolean;
	isMinimized: boolean;
	isWidgetOpenedOnce: boolean;
	onWidgetButtonHide: Lazy<void>;
	onClick(e: EventType, collapsedWidgetSelectedActionItem?: CollapsedWidgetButtonConfig): void;
	onMinimize: Lazy<void>;
}

export const CollapsedWidgetContainer: FC<CollapsedWidgetContainerProps> = ({
	isWidgetOpen,
	isMinimized,
	isWidgetOpenedOnce,
	onWidgetButtonHide,
	onClick,
	onMinimize,
}) => {
	const {
		i18n: { language },
	} = useTranslation();

	const {
		appConfig: {
			animations,
			settings,
			visualConfig: { collapsedWidgetType },
		},
		appOptions: { client, fullScreen },
	} = useContext(ConfigContext);
	const { getCollapsedWidgetConfig, getDismissButtonConfig, getMinimizeButtonConfig } = useContext(APIServiceContext);

	const eventsService = useContext(EventsServiceContext);

	const [collapsedButtonsConfig, setCollapsedButtonsConfig] =
		useState<Nullable<CollapsedWidgetConfigResponseModel>>();
	const [dismissButtonConfig, setDismissButtonConfig] = useState<DismissButtonConfig>();
	const [minimizeButtonConfig, setMinimizeButtonConfig] = useState<MinimizeButtonConfig>();
	const pathname = window.location.pathname || '/';

	const apiParams = toGetCollapsedWidgetConfigParams(language, client, pathname);

	const animationsList = animations.map(createAnimation);
	const tooltipAnimation = animationsList.find((animation) => animation.element === 'tooltip');

	useEffect(() => {
		getCollapsedWidgetConfig(apiParams)
			.then((data) => {
				const showComponentOncePerSession = data.visibility?.showComponentOncePerSession || false;
				if (data.enabled && shouldLoadComponent(showComponentOncePerSession, client, data.configSource)) {
					setCollapsedButtonsConfig(data);
				} else {
					setCollapsedButtonsConfig(null);
				}
			})
			.catch(() => setCollapsedButtonsConfig(null));

		getDismissButtonConfig(client)
			.then(flow(toDismissButtomConfig, setDismissButtonConfig))
			.catch(() => {
				console.error('Error while fetching dismiss button config');
			});
		getMinimizeButtonConfig(client)
			.then((minimizeConfig) => {
				if (Object.keys(minimizeConfig).length !== 0) {
					setMinimizeButtonConfig(minimizeConfig);
				}
			})
			.catch(() => {
				console.error('Error while fetching minimize button config');
			});
	}, []);

	useEffect(() => {
		if (collapsedButtonsConfig !== undefined) {
			const collapsedWidgetConfigEventData = {
				components: collapsedButtonsConfig
					? toAggregatedComponentsEventData(collapsedButtonsConfig, 'collapsedWidget')
					: {},
			};
			eventsService.sendEvent({
				eventName: 'GYANTLoaded',
				data: collapsedWidgetConfigEventData,
			});
		}
	}, [collapsedButtonsConfig]);

	const tooltipConfig = getTooltipConfig(settings.tooltipSetting, client);

	const getTooltipTitle = (language: string): string => {
		const localizedTooltip = settings.tooltipSetting.localizations.find(
			(localization) => localization.locale === language,
		);

		return localizedTooltip?.tooltip ?? settings.tooltipSetting.tooltip;
	};

	const tooltipTitle = getTooltipTitle(language);

	useEffect(() => {
		const isChatButtonMinimized = !!sessionStorage.getItem(`gyant_minimized_${client}`);
		if (isChatButtonMinimized) {
			onMinimize();
		}
	}, []);

	const handleMinimize = () => {
		if (minimizeButtonConfig?.oncePerSession) {
			sessionStorage.setItem(`gyant_minimized_${client}`, '1');
		}
		onMinimize();
		eventsService.sendEvent({
			eventName: 'minimizeButtonInteraction',
			data: {
				elementName: 'Minimize',
			},
		});
	};

	const handleHideCollapsedWidgetButtons = () => {
		const configSource = collapsedButtonsConfig?.configSource || null;
		setWasCollapsedWidgetButtonsComponentShown(client, configSource);
	};

	return (
		<CollapsedWidget
			onCollapsedWidgetButtonsHide={handleHideCollapsedWidgetButtons}
			buttonsConfig={collapsedButtonsConfig}
			dismissButtonConfig={dismissButtonConfig}
			minimizeButtonConfig={minimizeButtonConfig}
			onClick={onClick}
			onMinimize={handleMinimize}
			isMinimized={isMinimized}
			onWidgetButtonHide={onWidgetButtonHide}
			isWidgetOpenedOnce={isWidgetOpenedOnce}
			tooltipConfig={tooltipConfig}
			tooltipTitle={tooltipTitle}
			fullScreen={fullScreen}
			type={collapsedWidgetType}
			isWidgetClosed={!isWidgetOpen}
			tooltipAnimation={tooltipAnimation}
		/>
	);
};
