import { isLeft } from 'fp-ts/Either';
import { pipe } from 'fp-ts/lib/function';
import {
	array,
	boolean,
	Decoder,
	intersect,
	literal,
	nullable,
	number,
	record,
	string,
	struct,
	union,
	UnknownRecord,
} from 'io-ts/Decoder';

import {
	CarouselHistoryMessage,
	ClientTextHistoryMessage,
	ConversationHistory,
	LiveChatCommandMessage,
	QuickResponseHistoryMessage,
	TextHistoryMessage,
} from '../models/conversation.model';
import {
	AppointmentManagementMetaData,
	AppointmentSchedulingMetaData,
	AppointmentSchedulingSummaryMetaData,
	BasicMetaData,
	CardResponse,
	CarouselMessage,
	ClinicCard,
	ClinicCardData,
	DiagnosisCard,
	DiagnosisCardData,
	DoctorSearchFilter,
	DoctorSearchFilterItem,
	DoctorSearchMetaData,
	ErrorMessage,
	GenericQuickResponse,
	isEffectMessage,
	isUIMessage,
	LastLiveChatMessageMetaData,
	LiveChatAgentStartTypingMetadata,
	LiveChatAgentStartTypingTimeoutMetadata,
	LiveChatDoctorInfo,
	LiveChatInitMetaData,
	LiveChatStartMetadata,
	LLMResultMetaData,
	LLMTooltipConfig,
	MessageFromSocket,
	MetaData,
	ParsedData,
	PasswordResetMetaData,
	PasswordResetValidation,
	PlacesCard,
	PostMessageMetadata,
	Provider,
	ProviderAvailability,
	ProviderCard,
	ProviderLocation,
	ProviderReview,
	QuickResponseCheckbox,
	QuickResponseExternalLink,
	QuickResponseLocation,
	QuickResponseMessage,
	SatisfactionSurveyMetadata,
	SimpleCard,
	SpecialAction,
	TextMessage,
	UserSurveyMetaData,
	VisitType,
} from '../models/message.model';
import { optionalDecoder } from './decoder.utils';
import { mergeObjects } from './object.utils';
import { fallbackCarouselMessage, fallbackQuickResponseMessage, fallbackTextMessage } from './parser.utils';

export const EMPTY_QR_TEXT = '_';

const specialActionDecoder = struct<SpecialAction>({
	action: literal('setlocale'),
	value: literal('ar_AR', 'de_DE', 'en_US', 'es_LA', 'ru_RU', 'pt_PT', 'fr_FR', 'pt_BR', 'zh_CN'),
});

const appointmentSchedulingMetaDataDecoder = struct<AppointmentSchedulingMetaData>({
	data: struct({
		changeProviderFlowStep: string,
		providerName: string,
		scope: literal('OPEN_SCHEDULING', 'DIRECT_SCHEDULING'),
		flowId: string,
		stepName: string,
		criteria: UnknownRecord,
		calendarSearchTimeMonths: optionalDecoder(number),
		templates: struct({
			schedulerFootnote: optionalDecoder(string),
			changeProvider: optionalDecoder(string),
		}),
	}),
	caseId: string,
	status: literal('showAppointmentScheduling'),
});

const appointmentSchedulingSummaryMetaDataDecoder = struct<AppointmentSchedulingSummaryMetaData>({
	data: struct({
		appointmentValues: struct({
			appointmentDate: string,
			appointmentTime: string,
		}),
		contact: optionalDecoder(nullable(string)),
		location: optionalDecoder(
			nullable(
				struct({
					city: optionalDecoder(string),
					name: optionalDecoder(string),
					stateCode: optionalDecoder(string),
					street: optionalDecoder(string),
					zip: optionalDecoder(string),
				}),
			),
		),
		makeChangeFlowStep: string,
		cancelAppointmentFlowStep: optionalDecoder(string),
		providerName: string,
		templates: struct({
			additionalInformation: optionalDecoder(string),
		}),
		scope: string,
		flowId: string,
		stepName: string,
	}),
	caseId: string,
	status: literal('showAppointmentSummary'),
});

const userSurveyMetaDataDecoder = struct<UserSurveyMetaData>({
	caseId: string,
	status: literal('showUserSurvey'),
	data: struct({
		stepName: optionalDecoder(string),
		flowId: optionalDecoder(string),
	}),
});

const satisfactionSurveyMetaDataDecoder = struct<SatisfactionSurveyMetadata>({
	caseId: string,
	status: literal('showSatisfactionSurvey'),
	data: struct({
		buttons: array(
			struct({
				emojiType: string,
			}),
		),
		flowId: string,
		stepName: string,
	}),
});

const basicMetaDataDecoder = struct<BasicMetaData>({
	caseId: string,
	status: string,
});

const lastLiveChatFeedbackMessageMetaDataDecoder = struct<LastLiveChatMessageMetaData>({
	caseId: string,
	status: literal('lastLiveChatFeedbackMessage'),
	data: struct({
		stepName: optionalDecoder(string),
		flowId: optionalDecoder(string),
	}),
});

const appointmentManagementMetaDataDecoder = struct<AppointmentManagementMetaData>({
	caseId: string,
	status: literal('showAppointmentManagement'),
	data: struct({
		stepName: optionalDecoder(string),
		flowId: optionalDecoder(string),
		emptySlotsStep: string,
	}),
});

const doctorSeacrhFilterItemDecoder = struct<DoctorSearchFilterItem>({
	label: string,
	value: string,
});

const doctorSearchFilterDecoder = struct<DoctorSearchFilter>({
	name: string,
	type: literal('check', 'option', 'select', 'list'),
	label: string,
	multi: optionalDecoder(boolean),
	required: optionalDecoder(boolean),
	group: optionalDecoder(string),
	value: optionalDecoder(string),
	items: optionalDecoder(array(doctorSeacrhFilterItemDecoder)),
	placeholder: optionalDecoder(string),
});

const doctorSearchMetaDataDecoder = struct<DoctorSearchMetaData>({
	caseId: string,
	status: literal('showDoctorSearch'),
	data: struct({
		stepName: optionalDecoder(string),
		flowId: optionalDecoder(string),
		query: string,
		sorting: nullable(doctorSearchFilterDecoder),
		filters: array(doctorSearchFilterDecoder),
		emptySlotsStep: optionalDecoder(string),
		noDoctorsStep: optionalDecoder(string),
		searchContextLabel: nullable(string),
		options: nullable(UnknownRecord),
	}),
});

const passwordResetValidationDecoder = struct<PasswordResetValidation>({
	message: string,
	expression: string,
	isGeneral: boolean,
});

const passwordResetMetaDataDecoder = struct<PasswordResetMetaData>({
	caseId: string,
	status: literal('showPasswordReset'),
	data: struct({
		flowId: optionalDecoder(string),
		constraintsMessage: string,
		validations: array(passwordResetValidationDecoder),
	}),
});

const showLiveChatInitMetaDataDecoder = struct<LiveChatInitMetaData>({
	caseId: string,
	status: literal('showLiveChatInit'),
	data: struct({
		description: optionalDecoder(string),
		title: string,
		payload: string,
	}),
});

const llmSearchResultDecoder = struct({
	preview: string,
	text: string,
	title: string,
	url: string,
});

const llmTooltipConfigDecoder = struct<LLMTooltipConfig['templates']>({
	label: optionalDecoder(string),
	content: optionalDecoder(string),
});

const llmMetaDataDecoder = struct<LLMResultMetaData>({
	caseId: string,
	status: literal('llmResult'),
	data: struct({
		searchResults: array(llmSearchResultDecoder),
		content: string,
		llmConversationId: string,
		llmMessageId: optionalDecoder(string),
		tooltipConfig: struct<LLMTooltipConfig>({
			enabled: boolean,
			templates: optionalDecoder(llmTooltipConfigDecoder),
		}),
		stepName: optionalDecoder(string),
		flowId: optionalDecoder(string),
	}),
});

export const customMetadataDecoder = pipe(
	struct({
		status: literal('custom'),
	}),
	intersect(record(union(UnknownRecord, string, nullable(string), number, nullable(number)))),
);

const liveChatDoctorInfoDecoder = struct<LiveChatDoctorInfo>({
	displayName: string,
	firstName: optionalDecoder(string),
	lastName: optionalDecoder(string),
	initials: optionalDecoder(string),
	isLiveChatAgent: optionalDecoder(boolean),
});

const liveChatStartMetadataDecoder = struct<LiveChatStartMetadata>({
	caseId: string,
	status: literal('startLiveChat'),
	data: struct({
		queue: string,
		userData: struct({
			firstName: optionalDecoder(string),
			lastName: optionalDecoder(string),
			email: optionalDecoder(string),
		}),
	}),
});

const liveChatEndMetadataDecoder = struct({
	status: literal('endLiveChat'),
});

const liveChatAgentJoinedMetadataDecoder = struct({
	status: literal('agentJoined'),
});

const liveChatAgentLeftMetadataDecoder = struct({
	status: literal('agentLeft'),
});

const liveChatAgentStartTypingMetadataDecoder = struct<LiveChatAgentStartTypingMetadata>({
	status: literal('startTyping'),
});

const LiveChatAgentStartTypingTimeoutMetadataDecoder = struct<LiveChatAgentStartTypingTimeoutMetadata>({
	status: literal('startTyping'),
	data: struct({
		timeout: number,
	}),
});

const liveChatAgentEndTypingMetadataDecoder = struct({
	status: literal('endTyping'),
});

const postMessageMetadataDecoder = struct<PostMessageMetadata>({
	status: literal('postMessage'),
	data: UnknownRecord,
});

const metaDataDecoder = union(
	liveChatEndMetadataDecoder,
	liveChatAgentJoinedMetadataDecoder,
	liveChatAgentLeftMetadataDecoder,
	LiveChatAgentStartTypingTimeoutMetadataDecoder,
	liveChatAgentStartTypingMetadataDecoder,
	liveChatAgentEndTypingMetadataDecoder,
	liveChatStartMetadataDecoder,
	appointmentSchedulingMetaDataDecoder,
	appointmentSchedulingSummaryMetaDataDecoder,
	userSurveyMetaDataDecoder,
	satisfactionSurveyMetaDataDecoder,
	showLiveChatInitMetaDataDecoder,
	lastLiveChatFeedbackMessageMetaDataDecoder,
	appointmentManagementMetaDataDecoder,
	doctorSearchMetaDataDecoder,
	passwordResetMetaDataDecoder,
	postMessageMetadataDecoder,
	llmMetaDataDecoder,
	basicMetaDataDecoder,
	customMetadataDecoder,
);

const deliveryStatusDecoder = struct({
	messageId: string,
	status: literal('delivered', 'undelivered', 'sending'),
	isLocked: optionalDecoder(boolean),
});

const responseTypeDecoder = literal(
	'',
	'string',
	'date',
	'location',
	'phone',
	'mobile',
	'email',
	'data',
	'age',
	'temperature',
	'number',
	'complaints',
	'image',
	'intentbutton',
	'weight',
);

const errorMessageDecoder = struct<ErrorMessage>({
	type: literal('error'),
	details: struct({ code: string, message: string }),
});

const textMessageDecoder = struct<TextMessage>({
	type: literal('text', 'image', 'video', 'audio', 'message', 'command', 'messageStatus'),
	flowStep: optionalDecoder(string),
	content: string,
	text: string,
	incoming: boolean,
	autocompleteUri: optionalDecoder(string),
	autocomplete: optionalDecoder(string),
	isUndoable: optionalDecoder(boolean),
	isInputHidden: optionalDecoder(boolean),
	hasHelpfulnessSurvey: optionalDecoder(boolean),
	isExternal: optionalDecoder(boolean),
	messageStatus: optionalDecoder(deliveryStatusDecoder),
	responseType: optionalDecoder(responseTypeDecoder),
	talkingtodoctor: optionalDecoder(boolean),
	specialActions: optionalDecoder(array(specialActionDecoder)),
	metadata: optionalDecoder(metaDataDecoder),
	timeRemaining: optionalDecoder(number),
	doctorId: optionalDecoder(string),
	doctorInfo: optionalDecoder(liveChatDoctorInfoDecoder),
	nodeId: optionalDecoder(string),
});

const basicQuickResponseDecoder = {
	content: string,
	responseContext: string,
	evidenceExists: boolean,
	symptoms: optionalDecoder(UnknownRecord),
};

const genericQuickResponseDecoder = struct<GenericQuickResponse>({
	content: string,
	responseContext: string,
	evidenceExists: boolean,
	symptoms: optionalDecoder(UnknownRecord),
	link: optionalDecoder(string),
	type: string,
	buttonType: optionalDecoder(string),
});

const quickResponseButtonDecoder = struct({
	type: literal('text'),
	buttonType: optionalDecoder(literal('button')),
	...basicQuickResponseDecoder,
});

const quickResponseCheckboxDecoder = struct<QuickResponseCheckbox>({
	type: literal('text'),
	buttonType: literal('multi', 'exclusiveMulti'),
	...basicQuickResponseDecoder,
});

const quickResponseExternalLinkDecoder = struct<QuickResponseExternalLink>({
	type: literal('webUrlExternalWithResponse', 'webUrlExternal'),
	buttonType: optionalDecoder(literal('button')),
	link: string,
	...basicQuickResponseDecoder,
});

const quickResponseLocationDecoder = struct<QuickResponseLocation>({
	type: literal('location'),
	buttonType: optionalDecoder(literal('button')),
	...basicQuickResponseDecoder,
});

const quickResponsesDecoder = struct<QuickResponseMessage>({
	type: literal('quickResponses'),
	flowStep: string,
	text: string,
	responseType: responseTypeDecoder,
	incoming: boolean,
	isUndoable: boolean,
	isInputHidden: optionalDecoder(boolean),
	hasHelpfulnessSurvey: optionalDecoder(boolean),
	isExternal: optionalDecoder(boolean),
	talkingtodoctor: boolean,
	specialActions: optionalDecoder(array(specialActionDecoder)),
	metadata: optionalDecoder(metaDataDecoder),
	timeRemaining: optionalDecoder(number),
	responses: array(genericQuickResponseDecoder),
	nodeId: optionalDecoder(string),
});

const cardResponseDecoder = struct<CardResponse>({
	content: string,
	responseContext: string,
	type: literal('flowStep', 'quickResponse', 'webUrlExternalWithResponse', 'webUrlExternal'),
	link: optionalDecoder(string),
});

const simpleCardDecoder = struct<SimpleCard>({
	type: literal('card'),
	title: string,
	nodeId: string,
	imageUrl: optionalDecoder(string),
	subtitle: optionalDecoder(string),
	responses: array(cardResponseDecoder),
});

const placesCardDecoder = struct<PlacesCard>({
	type: literal('places'),
	name: string,
	phoneNumber: string,
	address: string,
	nodeId: string,
	distance: optionalDecoder(number),
	mapURL: optionalDecoder(string),
	openHours: optionalDecoder(string),
	responses: array(cardResponseDecoder),
});

const providerLocationDecoder = struct<ProviderLocation>({
	address: string,
	city: string,
	latitude: number,
	longitude: number,
	mapImage: string,
	name: optionalDecoder(nullable(string)),
	type: string,
	phone: optionalDecoder(nullable(string)),
	fax: optionalDecoder(nullable(string)),
	distance: optionalDecoder(nullable(number)),
	state: optionalDecoder(nullable(string)),
	externalDepartmentId: optionalDecoder(nullable(string)),
	externalProviderId: optionalDecoder(nullable(string)),
});

const providerAvailabilityDecoder = struct<ProviderAvailability>({
	nextDate: optionalDecoder(nullable(string)),
	availableInNextDays: optionalDecoder(nullable(number)),
});

const providerReviewDecoder = struct<ProviderReview>({
	count: number,
	ratingAverage: number,
	ratingCount: number,
	ratingPercentageNormalize: number,
	url: string,
});

const visitTypeDecoder = struct<VisitType>({
	id: string,
	label: optionalDecoder(string),
	name: nullable(optionalDecoder(string)),
	type: string,
});

const providerDecoder = struct<Provider>({
	acceptingNewPatients: nullable(boolean),
	acceptingVirtualVisits: nullable(boolean),
	allowsOpenScheduling: optionalDecoder(nullable(boolean)),
	externalProviderId: optionalDecoder(nullable(string)),
	address: nullable(optionalDecoder(string)),
	addressPlaceholder: string,
	appointmentUrl: nullable(optionalDecoder(string)),
	canBookOnline: optionalDecoder(nullable(boolean)),
	city: optionalDecoder(nullable(string)),
	fax: optionalDecoder(nullable(string)),
	clientOrganization: nullable(optionalDecoder(string)),
	id: optionalDecoder(nullable(union(number, string))),
	mapImage: optionalDecoder(nullable(string)),
	distance: optionalDecoder(nullable(number)),
	entityUrl: string,
	availability: nullable(optionalDecoder(providerAvailabilityDecoder)),
	gender: literal('f', 'm'),
	imageUrl: nullable(optionalDecoder(string)),
	isPcp: boolean,
	canBookOnlineAuth: optionalDecoder(nullable(boolean)),
	latitude: optionalDecoder(nullable(number)),
	longitude: optionalDecoder(nullable(number)),
	name: string,
	phone: nullable(optionalDecoder(string)),
	phonePlaceholder: string,
	sourceId: nullable(optionalDecoder(string)),
	link: nullable(optionalDecoder(string)),
	type: literal('provider'),
	url: string,
	state: optionalDecoder(nullable(string)),
	visitTypes: optionalDecoder(array(visitTypeDecoder)),
	specialties: array(string),
	languages: array(string),
	locations: array(providerLocationDecoder),
	reviews: optionalDecoder(nullable(providerReviewDecoder)),
});

const clinicCardDataDecoder = struct<ClinicCardData>({
	address: string,
	addressPlaceholder: nullable(optionalDecoder(string)),
	clientOrganization: nullable(optionalDecoder(string)),
	disposition: nullable(optionalDecoder(string)),
	distance: optionalDecoder(nullable(number)),
	externalDepartmentId: nullable(optionalDecoder(union(number, string))),
	externalProviderId: nullable(optionalDecoder(union(number, string))),

	fax: optionalDecoder(nullable(string)),
	latitude: number,
	link: nullable(optionalDecoder(string)),
	longitude: number,
	mapImage: string,
	name: string,
	phone: nullable(optionalDecoder(string)),
	phonePlaceholder: nullable(optionalDecoder(string)),
	scheduleUrl: nullable(optionalDecoder(string)),
	schedulingType: nullable(optionalDecoder(string)),
	city: nullable(optionalDecoder(string)),
	state: nullable(optionalDecoder(string)),
	sourceId: nullable(optionalDecoder(string)),
	type: literal('clinic'),
	url: nullable(optionalDecoder(string)),
	entityUrl: nullable(optionalDecoder(string)),
	visitTypes: optionalDecoder(array(visitTypeDecoder)),
	waitingTime: optionalDecoder(nullable(number)),

	waitingTimeString: nullable(optionalDecoder(string)),
	waitingTimeTitleString: nullable(optionalDecoder(string)),

	operationHoursTitleString: nullable(optionalDecoder(string)),
	operationHoursString: nullable(optionalDecoder(string)),
	operationHoursUrl: nullable(optionalDecoder(string)),
	operationHoursUrlString: nullable(optionalDecoder(string)),
	nextAppointmentSlotString: nullable(optionalDecoder(string)),
	nextAppointmentSlotTitleString: nullable(optionalDecoder(string)),
	availabilityString: nullable(optionalDecoder(string)),
	availabilityTitleString: nullable(optionalDecoder(string)),
	openingHours: nullable(optionalDecoder(string)),
});

const diagnosisCardDataDecoder = struct<DiagnosisCardData>({
	imageUrl: string,
	title: string,
	type: literal('card'),
	cardType: literal('diagnosis'),
});

const providerCardDecoder = struct<ProviderCard>({
	type: literal('provider'),
	title: string,
	nodeId: string,
	imageUrl: nullable(optionalDecoder(string)),
	responses: array(cardResponseDecoder),
	data: providerDecoder,
});

const clinicCardDecoder = struct<ClinicCard>({
	type: literal('clinic'),
	title: string,
	nodeId: string,
	responses: array(cardResponseDecoder),
	data: clinicCardDataDecoder,
});

const diagnosisCardDecoder = struct<DiagnosisCard>({
	type: literal('card'),
	nodeId: string,
	responses: array(cardResponseDecoder),
	imageUrl: string,
	cardType: literal('diagnosis'),
	title: string,
	data: diagnosisCardDataDecoder,
});

const carouselDecoder = struct<CarouselMessage>({
	type: literal('carousel'),
	flowStep: string,
	text: string,
	responseType: optionalDecoder(responseTypeDecoder),
	incoming: boolean,
	isUndoable: optionalDecoder(boolean),
	isInputHidden: optionalDecoder(boolean),
	hasHelpfulnessSurvey: optionalDecoder(boolean),
	isExternal: optionalDecoder(boolean),
	talkingtodoctor: optionalDecoder(boolean),
	timeRemaining: optionalDecoder(number),
	cards: array(
		union(providerCardDecoder, clinicCardDecoder, diagnosisCardDecoder, placesCardDecoder, simpleCardDecoder),
	),
	metadata: optionalDecoder(metaDataDecoder),
});

const checkMessage = <T>(
	decoder: Decoder<unknown, T>,
	value: any,
	fallbackMapper?: (message: unknown) => T,
): ParsedData<T> => {
	const decoded = decoder.decode(value);
	const isErrorParsing = isLeft(decoded);
	if (isErrorParsing) {
		console.log('Message parsing failed: ', decoded.left, value);
		return {
			data: fallbackMapper ? fallbackMapper(value) : null,
			parsingInfo: {
				status: 'failure',
				error: decoded.left,
				originalMessage: value,
			},
		};
	}
	return {
		data: mergeObjects(decoded.right, value),
		parsingInfo: { status: 'success' },
	};
};

const normalizeMessage = (message: any): MessageFromSocket => {
	if (isUIMessage(message)) {
		if (message.metadata && !('status' in message.metadata)) {
			(message.metadata as MetaData).status = 'custom';
			return message;
		}
		if (!message.text) {
			message.text = '';
		}
	}
	return message;
};

export const parseMessage = (message: MessageFromSocket | any): ParsedData<MessageFromSocket> => {
	if (isEffectMessage(message)) {
		// adding empty content if the message is an effect. This content is not used in the widget, just to message system consistency
		message.content = '';
	}
	// Remove metadata without status
	const standartizedMessages = normalizeMessage(message);

	if (message.type) {
		switch (message.type) {
			case 'messageStatus':
			case 'command':
			case 'text':
			case 'image':
			case 'video':
			case 'audio':
				return checkMessage<TextMessage>(textMessageDecoder, standartizedMessages, fallbackTextMessage);
			case 'quickResponses':
				return checkMessage<QuickResponseMessage>(
					quickResponsesDecoder,
					standartizedMessages,
					fallbackQuickResponseMessage,
				);
			case 'carousel':
				return checkMessage<CarouselMessage>(carouselDecoder, standartizedMessages, fallbackCarouselMessage);
			case 'error':
				return checkMessage<ErrorMessage>(errorMessageDecoder, standartizedMessages);
			default:
				return {
					data: null,
					parsingInfo: {
						status: 'failure',
						originalMessage: message,
						error: 'Unknown message type',
					},
				};
		}
	}
	return {
		data: null,
		parsingInfo: {
			status: 'failure',
			originalMessage: message,
			error: 'No type found in message',
		},
	};
};

const clientTextHistoryMessageDecoder = struct<ClientTextHistoryMessage>({
	attachments: array(string),
	incoming: literal(true),
	originalText: optionalDecoder(string),
	requestId: nullable(string),
	sanitizedText: optionalDecoder(string),
	text: string,
	timestamp: string,
	isInputHidden: optionalDecoder(boolean),
	hasHelpfulnessSurvey: optionalDecoder(boolean),
	isExternal: optionalDecoder(boolean),
	_id: string,
	messageId: optionalDecoder(string),
});

const liveChatCommandHistoryMessageDecoder = struct<LiveChatCommandMessage>({
	attachments: array(string),
	incoming: literal(true),
	type: literal('command'),
	value: string,
	requestId: nullable(string),
	originalText: string,
	timestamp: string,
	_id: string,
});

const textHistoryMessageDecoder = struct<TextHistoryMessage>({
	attachments: array(string),
	content: optionalDecoder(string),
	flowStep: optionalDecoder(string),
	incoming: literal(false),
	requestId: nullable(string),
	text: string,
	timestamp: string,
	type: literal('text', 'image', 'video', 'audio', 'message', 'command', 'messageStatus'),
	specialActions: optionalDecoder(array(specialActionDecoder)),
	timeRemaining: optionalDecoder(number),
	metadata: optionalDecoder(metaDataDecoder),
	isInputHidden: optionalDecoder(boolean),
	hasHelpfulnessSurvey: optionalDecoder(boolean),
	isExternal: optionalDecoder(boolean),
	_id: string,
	doctorId: optionalDecoder(string),
	doctorInfo: optionalDecoder(liveChatDoctorInfoDecoder),
	messageStatus: optionalDecoder(deliveryStatusDecoder),
});

const quickResponseHistoryMessageDecoder = struct<QuickResponseHistoryMessage>({
	attachments: array(string),
	flowStep: string,
	incoming: literal(false),
	requestId: nullable(string),
	responses: array(
		union(
			quickResponseButtonDecoder,
			quickResponseCheckboxDecoder,
			quickResponseExternalLinkDecoder,
			quickResponseLocationDecoder,
		),
	),
	text: string,
	timestamp: string,
	type: literal('quickResponses'),
	specialActions: optionalDecoder(array(specialActionDecoder)),
	timeRemaining: optionalDecoder(number),
	isInputHidden: optionalDecoder(boolean),
	hasHelpfulnessSurvey: optionalDecoder(boolean),
	isExternal: optionalDecoder(boolean),
	metadata: optionalDecoder(metaDataDecoder),
	_id: string,
});

export const carouselHistoryMessageDecoder = struct<CarouselHistoryMessage>({
	attachments: array(string),
	cards: array(union(providerCardDecoder, clinicCardDecoder, diagnosisCardDecoder, simpleCardDecoder)),
	flowStep: string,
	incoming: literal(false),
	requestId: nullable(string),
	text: string,
	timestamp: string,
	type: literal('carousel'),
	timeRemaining: optionalDecoder(number),
	_id: string,
	isInputHidden: optionalDecoder(boolean),
	hasHelpfulnessSurvey: optionalDecoder(boolean),
	isExternal: optionalDecoder(boolean),
});

const conversationHistoryDecoder = struct<ConversationHistory>({
	context: struct({
		isLiveChatActive: boolean,
		isUndoable: boolean,
		responseType: responseTypeDecoder,
		talkingtodoctor: boolean,
		autocompleteUri: optionalDecoder(string),
		hasHelpfulnessSurvey: optionalDecoder(boolean),
		isExternal: optionalDecoder(boolean),
	}),
	metadata: struct({ caseId: optionalDecoder(string), status: string }),
	utterances: array(
		union(
			carouselHistoryMessageDecoder,
			quickResponseHistoryMessageDecoder,
			textHistoryMessageDecoder,
			clientTextHistoryMessageDecoder,
			liveChatCommandHistoryMessageDecoder,
		),
	),
});

export const validateHistory = (history: ConversationHistory | any): ParsedData<ConversationHistory> => {
	if (history && Array.isArray(history.utterances)) {
		// TODO: remove filtering of the attachments when it will be needed in the widget
		history.utterances = history.utterances.map(normalizeMessage).filter(isUIMessage);
	}
	return checkMessage(conversationHistoryDecoder, history);
};
