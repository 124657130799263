import { pipe } from 'fp-ts/lib/function';
import { fold, fromNullable, getOrElse } from 'fp-ts/lib/Option';

import {
	AppConfigResponseModel,
	ColorSchemeModel,
	LanguageModel,
	LocaleModel,
	MenuItemModel,
	SettingsModel,
	ThemeConfig,
	VisualConfigModel,
	WebTrackerChatEvent,
	WebTrackerEventBodyModel,
	WebTrackerModel,
	WebTrackerUIEvent,
} from '../../context/api-service/api-service.model';
import {
	AppConfig,
	AppOptions,
	AutoOpen,
	CollapsedWidgetType,
	ColorScheme,
	DEFAULT_APP_CONFIG,
	DEFAULT_APP_OPTIONS,
	DEFAULT_BRAND_COLOR,
	DEFAULT_COLOR_SCHEME,
	DEFAULT_PRIMARY_COLOR,
	DEFAULT_SETTINGS,
	DEFAULT_VISUAL_CONFIG,
	MenuItem,
	MenuTextLocalization,
	Settings,
	TooltipSettings,
	VisualConfig,
} from '../../models/app.model';
import {
	Language,
	LocaleLocalization,
	localeModelToLocale,
	localizationModelToLocale,
	toLanguageLocale,
} from '../../models/languages.model';
import { DEFAULT_WEB_TRACKER_CONFIG, WebTrackerAction, WebTrackerConfig } from '../../models/trackers.model';
import { createColorVariables } from '../../utils/colors.utils';
import { isMobileDeviceByTouchPoints } from '../../utils/device.utils';
import { useDefaultIfUndefined } from '../../utils/function.utils';
import { DeepPartial, isDefined, Nullable } from '../../utils/types.utils';

export const FONTS_URL = "@import url('https://s3.amazonaws.com/assets.gyant.com/fonts/fonts.css');";

export type InitialAppOption = DeepPartial<AppOptions>;

const toLanguagesFromResponseModel = (languages: Nullable<LanguageModel[]>): Language[] =>
	!languages
		? []
		: languages.map(({ label, text }) => ({
				label: text,
				language: label,
			}));

const toTrackerEvent = (eventsList: WebTrackerUIEvent | WebTrackerChatEvent): WebTrackerConfig['events'] =>
	Object.keys(eventsList).map((key) => {
		const { enabled, action, label, matchResponses }: WebTrackerEventBodyModel =
			eventsList[key as keyof typeof eventsList];

		return {
			type: key as WebTrackerAction, // need that "hack" since Object.keys is not typed
			enabled,
			action,
			label,
			matchResponses,
		};
	});

const toWebTrackersFromResponseModel = (trackers: Nullable<WebTrackerModel>): WebTrackerConfig => {
	if (!trackers) {
		return DEFAULT_WEB_TRACKER_CONFIG;
	}

	const chatEvents = toTrackerEvent(trackers.chat);
	const uiEvents = toTrackerEvent(trackers.ui);

	const trackerConfig = {
		...DEFAULT_WEB_TRACKER_CONFIG,
		category: trackers.category,
		logEventsToConsole: trackers.logEventsToConsole,
		trackers: trackers.trackers,
		events: [...chatEvents, ...uiEvents],
	};

	return trackerConfig;
};

const toAutoOpenFromResponseModel = (autoOpen: SettingsModel['autoOpen']): AutoOpen => {
	const device = autoOpen.desktop ? 'DESKTOP' : autoOpen.mobile ? 'MOBILE' : 'NONE';
	return {
		device,
		afterMs: autoOpen.afterMs,
	};
};

const toMenuTextSettingsFromResponseModel = (languages: Nullable<LanguageModel[]>): MenuTextLocalization[] =>
	languages && languages.length > 0
		? languages.map((language) => ({
				locale: localizationModelToLocale(language.locale),
				menu: language.menu || DEFAULT_SETTINGS.menuTextSetting[0].menu,
			}))
		: DEFAULT_SETTINGS.menuTextSetting;

const toTooltipSettingsFromResponseModel = (
	settings: Nullable<Partial<SettingsModel>>,
	languages: Nullable<LanguageModel[]>,
): TooltipSettings => ({
	tooltip: settings?.tooltip || DEFAULT_SETTINGS.tooltipSetting.tooltip,
	tooltipVisibleTimeMs: pipe(
		fromNullable(settings?.tooltipVisibleTimeMs),
		getOrElse(() => DEFAULT_SETTINGS.tooltipSetting.tooltipVisibleTimeMs),
	),
	keepTooltipVisible: Boolean(settings?.tooltipSettings?.keepTooltipVisible),
	showTooltipOncePerSession: Boolean(settings?.tooltipSettings?.showTooltipOncePerSession),
	localizations:
		languages?.map((language) => ({
			locale: localizationModelToLocale(language.locale),
			tooltip: language.tooltip,
		})) || DEFAULT_SETTINGS.tooltipSetting.localizations,
});

const toSettingsFromResponseModel = (
	settings: Nullable<Partial<SettingsModel>>,
	languages: Nullable<LanguageModel[]>,
): Settings => {
	if (!settings) {
		return DEFAULT_SETTINGS;
	}

	const defaultLanguage = settings.default_language ? toLanguageLocale(settings.default_language) : 'en';

	const cookies = {
		expireTimeout: settings.cookies?.expireTimeout || DEFAULT_SETTINGS.cookies.expireTimeout,
		enabled:
			settings.cookies && isDefined(settings.cookies.enabled)
				? settings.cookies.enabled
				: DEFAULT_SETTINGS.cookies.enabled,
		removeCookiesOnCollapse:
			settings.cookies?.removeCookiesOnCollapse || DEFAULT_SETTINGS.cookies.removeCookiesOnCollapse,
		disableWidgetOnCookieExpire:
			settings.cookies?.disableWidgetOnCookieExpire || DEFAULT_SETTINGS.cookies.disableWidgetOnCookieExpire,
	};

	const autoOpen = settings.autoOpen ? toAutoOpenFromResponseModel(settings.autoOpen) : DEFAULT_SETTINGS.autoOpen;

	return {
		autoOpen,
		maxMessageDelayMs: settings.maxMessageDelayMs || DEFAULT_SETTINGS.maxMessageDelayMs,
		progressBar: settings.progressBar || DEFAULT_SETTINGS.progressBar,
		defaultLanguage,
		cookies,
		tooltipSetting: toTooltipSettingsFromResponseModel(settings, languages),
		menuTextSetting: toMenuTextSettingsFromResponseModel(languages),
		useBrowserLanguage: settings.useBrowserLanguage || DEFAULT_SETTINGS.useBrowserLanguage,
		postMessageToParent: settings.postMessageToParent || DEFAULT_SETTINGS.postMessageToParent,
		preLoadFirstMessage: settings.preLoadFirstMessage || DEFAULT_SETTINGS.preLoadFirstMessage,
		addFullScreenCloseBtn: settings.fullscreen?.addCloseBtn || DEFAULT_SETTINGS.addFullScreenCloseBtn,
		confirmOpenLink: settings.links?.confirmOpenLink || DEFAULT_SETTINGS.confirmOpenLink,
		linkTarget: settings.links?.linkTarget,
		themeConfig: settings.themeConfig || DEFAULT_SETTINGS.themeConfig,
	};
};

const formatLocale = (locale: string): LocaleModel =>
	locale
		.split('_')
		.map((el, index) => (index === 1 ? el.toUpperCase() : el))
		.join('_') as LocaleModel;

const toMenuFromMenuResponseModel = (menuModel: MenuItemModel[]): MenuItem[] =>
	menuModel.map((menuItemModel) => {
		const wrappedLocale = menuItemModel.locale.includes('_')
			? formatLocale(menuItemModel.locale)
			: menuItemModel.locale;
		return {
			locale: localeModelToLocale(wrappedLocale),
			menuItemActions: menuItemModel.call_to_actions,
		};
	});

const getChatWindowBottomPosition = (collapsedWidgetType: CollapsedWidgetType): string => {
	switch (collapsedWidgetType) {
		case 'short':
			return '85px';
		case 'tall':
			return '104px';
	}
};

export const isLanguageSettingExists = (languages: Language[], language: LocaleLocalization): boolean =>
	languages.some((lng) => lng.language.toLowerCase() === language);

const toColorSchemeFromResponseModel = (
	colorSchemeModel: Nullable<ColorSchemeModel>,
	visualConfig: VisualConfig,
	themeConfig?: ThemeConfig,
): ColorScheme => {
	if (!colorSchemeModel) {
		return DEFAULT_COLOR_SCHEME;
	}

	const primaryColor = colorSchemeModel.colorPrimary || DEFAULT_PRIMARY_COLOR;
	const brandColor = colorSchemeModel.brandColor || DEFAULT_BRAND_COLOR;
	const chatWindowBottomPosition = getChatWindowBottomPosition(visualConfig.collapsedWidgetType);
	const customColorVariables = colorSchemeModel.customColorVariables;

	return {
		lightest: colorSchemeModel.lightest,
		light: colorSchemeModel.light,
		mediumLight: colorSchemeModel.mediumLight,
		lightBrandBackground: colorSchemeModel.lightBrandBackground,
		mediumDark: colorSchemeModel.mediumDark,
		dark: colorSchemeModel.dark,
		darkest: colorSchemeModel.darkest,
		logoBG: `${colorSchemeModel.imagesBasePathClient}${colorSchemeModel.logoBG}`,
		collapsedChatButtonImageUrl: `${colorSchemeModel.imagesBasePathClient}icon-chat-expand.svg`,
		logoBGWidth: colorSchemeModel.logoBGWidth,
		logoBGHeight: colorSchemeModel.logoBGHeight,
		mobileLogoBgWidth: colorSchemeModel.mobileLogoBgWidth,
		mobileLogoBgHeight: colorSchemeModel.mobileLogoBgHeight,
		// ---- optional
		botAvatar: colorSchemeModel.botAvatar
			? `${colorSchemeModel.imagesBasePathClient}${colorSchemeModel.botAvatar}`
			: undefined,
		chatWindowHeight: colorSchemeModel.chatWindowHeight || DEFAULT_COLOR_SCHEME.chatWindowHeight,
		chatWindowBottomPosition: colorSchemeModel.chatWindowBottomPosition || chatWindowBottomPosition,
		chatWindowRightPosition:
			colorSchemeModel.chatWindowRightPosition || DEFAULT_COLOR_SCHEME.chatWindowRightPosition,
		customStyle: colorSchemeModel.customStyle || {},
		customSassCode: colorSchemeModel.customSassCode,
		providerBubbleColor: colorSchemeModel.providerBubbleColor || colorSchemeModel.lightest,
		providerFontColor: colorSchemeModel.providerFontColor || colorSchemeModel.darkest,
		fontFamilyRegular: colorSchemeModel.fontFamilyRegular || DEFAULT_COLOR_SCHEME.fontFamilyRegular,
		fontFamilyMedium: colorSchemeModel.fontFamilyMedium || DEFAULT_COLOR_SCHEME.fontFamilyMedium,
		mobileHeaderHeight: colorSchemeModel.mobileHeaderHeight || DEFAULT_COLOR_SCHEME.mobileHeaderHeight,
		mobileHeaderLogoWidth: colorSchemeModel.mobileHeaderLogoWidth || DEFAULT_COLOR_SCHEME.mobileHeaderLogoWidth,
		mobileHeaderLogoHeight: colorSchemeModel.mobileHeaderLogoHeight || DEFAULT_COLOR_SCHEME.mobileHeaderLogoHeight,
		colorVariables: createColorVariables(primaryColor, brandColor, themeConfig, customColorVariables),
	};
};
const toVisualConfigFromResponseModel = (visualConfig: Nullable<VisualConfigModel>): VisualConfig =>
	pipe(
		visualConfig,
		fromNullable,
		fold(
			() => DEFAULT_VISUAL_CONFIG,
			(visualConfig) => ({
				defaultFontSize: visualConfig.defaultFontSize || DEFAULT_VISUAL_CONFIG.defaultFontSize,
				radiusType: visualConfig.radiusType || DEFAULT_VISUAL_CONFIG.radiusType,
				widgetBackground: visualConfig.widgetBackground || DEFAULT_VISUAL_CONFIG.widgetBackground,
				animateBackground: visualConfig.animateBackground || DEFAULT_VISUAL_CONFIG.animateBackground,
				collapsedWidgetType: visualConfig.collapsedWidgetType || DEFAULT_VISUAL_CONFIG.collapsedWidgetType,
				headerAvatar: visualConfig.headerAvatar,
				buttonAvatar: visualConfig.buttonAvatar,
				assistantName: visualConfig.assistantName,
				chipsType: visualConfig.chipsType || DEFAULT_VISUAL_CONFIG.chipsType,
				commonCarouselResponseFlows:
					visualConfig.commonCarouselResponseFlows || DEFAULT_VISUAL_CONFIG.commonCarouselResponseFlows,
			}),
		),
	);

export const mapAppConfigResponseToAppConfig = (responseConfig: AppConfigResponseModel): AppConfig => {
	const visualConfig = toVisualConfigFromResponseModel(responseConfig.visualConfig);
	return {
		languages: toLanguagesFromResponseModel(responseConfig.languages),
		animations: responseConfig.startingAnimations ?? [],
		webTracker: toWebTrackersFromResponseModel(responseConfig.webTrackers),
		settings: toSettingsFromResponseModel(responseConfig.settings, responseConfig.languages),
		menu: responseConfig.menu ? toMenuFromMenuResponseModel(responseConfig.menu) : [],
		colorScheme: toColorSchemeFromResponseModel(
			responseConfig.colorScheme,
			visualConfig,
			responseConfig.settings?.themeConfig,
		),
		visualConfig,
		documentTitle: responseConfig.documentTitle || DEFAULT_APP_CONFIG.documentTitle,
	};
};

export const getAppOptionsFromDifferentSources = (viaProps: InitialAppOption, viaUrl: InitialAppOption): AppOptions => {
	const onBotMessage = useDefaultIfUndefined(DEFAULT_APP_OPTIONS.events.onBotMessage, viaProps.events?.onBotMessage);
	const onWidgetVisibilityChange = useDefaultIfUndefined(
		DEFAULT_APP_OPTIONS.events.onWidgetVisibilityChange,
		viaProps.events?.onWidgetVisibilityChange,
	);
	const onWidgetLoad = useDefaultIfUndefined(DEFAULT_APP_OPTIONS.events.onWidgetLoad, viaProps.events?.onWidgetLoad);

	return {
		...DEFAULT_APP_OPTIONS,
		...viaProps,
		...viaUrl,
		events: {
			onBotMessage,
			onWidgetVisibilityChange,
			onWidgetLoad,
		},
		client: viaProps.client || viaProps.org || viaUrl.client || viaUrl.org || DEFAULT_APP_OPTIONS.client,
	};
};

export const checkAutoOpen = (autoOpen: AutoOpen, autoOpenHandler: (state: boolean, timeOut: number) => void): void => {
	const isMobileDevice = isMobileDeviceByTouchPoints();
	const { device, afterMs } = autoOpen;
	switch (device) {
		case 'DESKTOP':
			!isMobileDevice && autoOpenHandler(true, afterMs);
			break;
		case 'MOBILE':
			isMobileDevice && autoOpenHandler(true, afterMs);
			break;
		case 'NONE':
			break;
	}
};
