import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import React, { FC, ReactNode } from 'react';

import { ThemeConfig } from '../../context/api-service/api-service.model';
import { ColorScheme, VisualConfig } from '../../models/app.model';
import { createColorTheme } from './theme.default';

interface ThemeProps {
	children: ReactNode;
	colorScheme: ColorScheme;
	visualConfig: VisualConfig;
	isGlobalStyles?: boolean;
	noAnimationTransition?: boolean;
	themeConfig?: ThemeConfig;
}

export const Theme: FC<ThemeProps> = ({
	colorScheme,
	visualConfig,
	isGlobalStyles = false,
	noAnimationTransition = false,
	children,
	themeConfig,
}) => {
	const theme = createColorTheme(colorScheme, visualConfig, { isGlobalStyles, noAnimationTransition, themeConfig });
	return (
		<ThemeProvider theme={theme}>
			{isGlobalStyles && <CssBaseline />}
			{children}
		</ThemeProvider>
	);
};
