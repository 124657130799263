import { makeStyles, Theme } from '@material-ui/core';

interface InputAutocompleteStylesProps {
	isFocused: boolean;
	isLiveChatShown: boolean;
	hasOptions: boolean;
	isSendFreeTextDisabled: boolean;
}

export const useInputAutocompleteStyles = makeStyles<Theme, InputAutocompleteStylesProps>(
	({ palette: { colorScheme, borderRadius, boxShadow, sizes }, typography: { smallViewPort } }) => ({
		root: {
			position: 'relative',
			height: '100%',
			width: '100%',
			borderRadius: borderRadius.button,
			transition: 'all 0.8s',
			'&.Mui-focused': {
				transition: (props) => (props.isFocused ? 'all 0.1s' : 'none'),
				boxShadow: `0 0 0 2px ${colorScheme.colorVariables.colorPrimary}`,
			},
		},
		listbox: {
			maxHeight: 278,
			padding: 0,
		},
		option: {
			fontWeight: 'bold',
			padding: '12px 16px',
			'&[data-focus="true"]': {
				backgroundColor: colorScheme.colorVariables.colorPrimarySoft,
			},
		},
		popper: {
			bottom: sizes.size_7,
			left: 0,
			right: 0,
			// Need that to rewrite inline style set by Material ui
			width: '100% !important',
			boxShadow: boxShadow.angle.xlarge,
			borderRadius: borderRadius.button,
			padding: (props) => (props.hasOptions ? sizes.sizeHalf : 0),
			backgroundColor: 'white',
		},
		paper: {
			color: colorScheme.colorVariables.colorText,
			borderRadius: borderRadius.button,
			margin: 0,
			boxShadow: 'none',
		},
		noOptions: {
			display: 'none',
		},
		endAdornment: {
			display: 'none',
		},
		inputRoot: {
			transition: 'all 0.8s',
			marginBottom: 0,
			paddingRight: '0px !important',
			border: `1px solid ${colorScheme.colorVariables.colorBorder}`,
			boxShadow: boxShadow.angle.xsmall,
			background: 'white',
			borderRadius: borderRadius.button,
			'&:first-child': {
				transition: 'padding 0s',
				padding: (props) => `4px 16px 6px ${props.isSendFreeTextDisabled ? '50px' : '16px'}`,
				minHeight: 48,
			},
			'& textarea:first-child': {
				overflowY: (props) => (props.isFocused ? 'auto' : 'hidden'),
				transition: 'all 0.2s',
				paddingRight: (props) => `${props.isSendFreeTextDisabled ? 0 : 36}px !important`,
				borderTopRightRadius: borderRadius.button,
				borderBottomRightRadius: borderRadius.button,
			},
			'&:hover': {
				transition: 'all 0.1s',
				border: `1px solid ${colorScheme.colorVariables.colorNote}`,
			},
			'&.Mui-focused': {
				transition: 'all 0.1s',
				border: '1px solid transparent',
			},
			'&.Mui-disabled': {
				border: `1px solid ${colorScheme.colorVariables.colorInactive}`,
			},
			'&.Mui-disabled:hover': {
				border: `1px solid ${colorScheme.colorVariables.colorInactive}`,
			},
			...smallViewPort.typeInput1,
			...colorScheme.customStyle.userInput,
			fontFamily: (props) =>
				props.isLiveChatShown ? colorScheme.fontFamilyRegular : smallViewPort.typeInput1.fontFamily,
		},
	}),
);

export const useTextInputClasses = makeStyles<Theme>(
	({
		palette: {
			colorScheme: { colorVariables },
		},
	}) => ({
		disabled: {
			transition: 'background 0.1s',
			background: colorVariables.colorInactive,
			color: colorVariables.colorNote,
			boxShadow: 'none',
			border: 'none',
			outline: 'none',
			'&:hover': {
				outline: 'none',
			},
		},
	}),
);

export const useAutocompleteStyles = makeStyles<Theme>(({ palette: { colorScheme, sizes, borderRadius } }) => ({
	adornmentContainer: {
		position: 'relative',
	},
	sendButton: {
		position: 'absolute',
		right: sizes.sizeHalf,
		bottom: sizes.sizeHalf,
		height: sizes.size_5,
		borderRadius: borderRadius.button - 4 > 0 ? borderRadius.button - 4 : 0,
		...colorScheme.customStyle.sendButton,
	},
	searchIconAdornment: {
		position: 'absolute',
		top: 0,
		left: 12,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		zIndex: 1,
	},
	clearButton: {
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: 'transparent',
		},
		'&:focus-visible': {
			outlineOffset: -1,
		},
	},
	highlighted: {
		fontWeight: 'normal',
	},
	hidden: {
		display: 'none',
	},
}));
