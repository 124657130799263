import classNames from 'classnames';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CardResponse, PlacesCard as PlacesCardType } from '../../../../../models/message.model';
import { EventType } from '../../../../../services/web-trackers-service/web-tracker-service';
import { Button } from '../../../../../ui-kit/button/button.component';
import { CarouselCard } from '../../../../../ui-kit/carousel/carousel-card/carousel-card.component';
import { Icon } from '../../../../../ui-kit/icon/icon.component';
import { ClockWatchIcon } from '../../../../../ui-kit/icons/clock-watch.icon';
import { PhoneFilledIcon } from '../../../../../ui-kit/icons/phone-filled.icon';
import { Link } from '../../../../../ui-kit/link/link.component';
import { createDistance } from '../../../../../utils/distance.utils';
import { Nullable } from '../../../../../utils/types.utils';
import { useCareVenueCardStyles } from './places-card.styles';

interface PlacesCardProps {
	card: PlacesCardType;
	onResponse: (response: CardResponse, e: EventType) => void;
}

export const PlacesCard: FC<PlacesCardProps> = memo(({ card, onResponse }) => {
	const { t } = useTranslation();
	const classes = useCareVenueCardStyles();
	//const { name, phoneNumber, address, distance, mapURL, openHours, responses } = card;
	const { data, responses } = card;
	const { name, phoneNumber, address = '', distance, mapURL, openingHours } = data;

	const createActions = (responses: CardResponse[]): JSX.Element => (
		<div className={classes.actionsRoot} data-testing-label={'carousel-places-card-action-container'}>
			{responses.map((response, index) => (
				<Button
					key={index}
					className={classes.actionButton}
					color={index === 0 ? 'primary' : 'secondary'}
					onClick={(e) => onResponse(response, e)}
					data-testing-label={`carousel-places-card-${index === 0 ? 'main' : 'sub'}-action`}>
					{response.content}
				</Button>
			))}
		</div>
	);

	const phoneLink = phoneNumber && (
		<Link
			linkType={'typeLink2'}
			href={`tel:${phoneNumber}`}
			target={'_parent'}
			variant={'button'}
			className={classes.additionalActionLink}
			data-testing-label={'carousel-places-card-phone-link'}>
			<Icon icon={PhoneFilledIcon} size={'large'} iconType={'buttonIcon'} alt={'call icon'} />
			<span className={classes.actionLinkLabel}>{t('call', 'Call')}</span>
		</Link>
	);

	const renderDistanceAndAddress = (address: string, distance?: Nullable<number>) => (
		<div className={classNames(classes.withSeparator, classes.address)}>
			{distance && (
				<span data-testing-label={'carousel-places-card-distance'}>{createDistance(distance, t)}</span>
			)}
			<span data-testing-label={'carousel-places-card-address'}>{address}</span>
		</div>
	);

	const renderPhone = (phoneNumber: string) => (
		<div className={classes.infoLine}>
			<strong>P</strong>
			<span data-testing-label={'carousel-places-card-phone-number'}> {phoneNumber}</span>
		</div>
	);

	const renderOpeningHours = (openingHours: string) => (
		<span className={classNames(classes.openHours, classes.infoLine)}>
			<Icon icon={ClockWatchIcon} size={'small'} iconType={'decorIcon'} alt={'opening hours icon'} />
			<span data-testing-label={'carousel-places-card-opening-hours'}> {openingHours}</span>
		</span>
	);

	return (
		<CarouselCard>
			<div className={classes.root} data-testing-label={'carousel-places-card'}>
				{mapURL && (
					<img
						src={mapURL}
						alt={'places-card-map'}
						className={classes.image}
						data-testing-label={'carousel-places-card-map-image'}
					/>
				)}
				<div className={classes.content}>
					<h2
						className={classes.title}
						data-testing-label={'carousel-places-card-title'}
						dangerouslySetInnerHTML={{ __html: name }}
					/>
					{renderDistanceAndAddress(address, distance)}
					{(phoneNumber || openingHours) && (
						<div className={classes.contacts}>
							{phoneNumber && renderPhone(phoneNumber)}
							{openingHours && renderOpeningHours(openingHours)}
						</div>
					)}
					{phoneLink}
				</div>
				{createActions(responses)}
			</div>
		</CarouselCard>
	);
});
