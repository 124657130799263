import { ThemeConfig } from '../context/api-service/api-service.model';
import { ColorVariables, DEFAULT_COLOR_VARIABLES, DEFAULT_SETTINGS } from '../models/app.model';
import { BoxShadowConfig } from '../models/box-shadow.model';

export const geBoxShadowConfig = (
	themeConfig: ThemeConfig = DEFAULT_SETTINGS.themeConfig,
	colorVariables: ColorVariables = DEFAULT_COLOR_VARIABLES,
): BoxShadowConfig => {
	const isFabricV2 = themeConfig.version === 'fabric.v2';
	const { colorPrimary, colorMediumShadow, colorLightShadow, colorDarkShadow } = colorVariables;

	return {
		button: {
			primary: isFabricV2 ? 'none' : `0 4px 0 -2px ${colorMediumShadow}`,
			active: isFabricV2 ? 'none' : `0px 0px 0px 1px ${colorPrimary}`,
		},
		angle: {
			xsmall: isFabricV2 ? '0px 1px 5px 0px rgba(0, 0, 0, 0.10)' : `0 4px 0 -2px ${colorLightShadow}`,
			small: '0px 3px 7px 0px rgba(0, 0, 0, 0.10)',
			medium: '0px 4px 12px 0px rgba(0, 0, 0, 0.08)',
			large: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)',
			xlarge: isFabricV2 ? '0px 32px 40px 0px rgba(0, 0, 0, 0.04)' : `0 16px 56px 0 ${colorDarkShadow}`,
		},
		special: {
			toggle: '0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.16), 0px 3px 1px 0px rgba(0, 0, 0, 0.10)',
			hero: '0px 256px 72px 0px rgba(0, 0, 0, 0.00), 0px 164px 65px 0px rgba(0, 0, 0, 0.01), 0px 92px 55px 0px rgba(0, 0, 0, 0.02), 0px 41px 41px 0px rgba(0, 0, 0, 0.04), 0px 10px 23px 0px rgba(0, 0, 0, 0.05)',
		},
	};
};
