import { makeStyles, Theme } from '@material-ui/core';

export const useHelpfulnessSurveyExternalStyles = makeStyles<Theme, { hasWidget: boolean }>(
	({
		palette: {
			sizes,
			colorScheme: { chatWindowBottomPosition, colorVariables },
			borderRadius,
			boxShadow,
		},
	}) => ({
		root: {
			backgroundColor: 'white',
			zIndex: 100000001,
			right: sizes.size_2,
			position: 'fixed',
			borderRadius: borderRadius.widget,
			boxShadow: boxShadow.angle.xlarge,
			alignItems: 'center',
			display: 'flex',
			width: 'fit-content',
			padding: `${sizes.size_1}px ${sizes.size_3}px ${sizes.size_1}px ${sizes.size_1}px`,
			bottom: (props) => (props.hasWidget ? chatWindowBottomPosition : sizes.size_2),
			opacity: 1,
		},
		buttonIcon: {
			width: sizes.size_4,
			height: sizes.size_4,
			backgroundColor: colorVariables.colorSysBgDefault,
			marginLeft: sizes.size_1 + sizes.sizeHalf,
			'&:hover': {
				backgroundImage: 'none',
				backgroundColor: 'hsla(0, 0%, 20%, 0.08)',
			},
		},
		avatar: {
			width: sizes.size_5,
			height: sizes.size_5,
			'& svg': {
				width: sizes.size_3 + sizes.sizeQuarter,
				height: sizes.size_3 + sizes.sizeQuarter,
			},
		},
		content: {
			marginLeft: sizes.size_2,
		},
	}),
);

export const helpfulnessAnimation = makeStyles<Theme, { hasWidget: boolean; timeout: { enter: number; exit: number } }>(
	({
		palette: {
			colorScheme: { chatWindowBottomPosition },
			sizes,
		},
	}) => ({
		enter: {
			opacity: 0,
			bottom: 0,
		},
		enterActive: {
			bottom: (props) => (props.hasWidget ? chatWindowBottomPosition : sizes.size_2),
			opacity: 1,
			transition: (props) => `all ${props.timeout.enter}ms`,
		},
		exitActive: {
			opacity: 0,
			transition: (props) => `all ${props.timeout.exit}ms`,
			bottom: 0,
		},
	}),
);
