import { useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import { FC, Fragment, memo, useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
	CollapsedWidgetCallActionItem,
	CollapsedWidgetConfigResponseModel,
} from '../../context/api-service/api-service.model';
import { CollapsedWidgetType } from '../../models/app.model';
import { MOBILE_MEDIA_QUERY } from '../../models/dimensions.model';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { IconButton } from '../../ui-kit/icon-button/icon-button.component';
import { Icon } from '../../ui-kit/icon/icon.component';
import { ArrowDownIcon } from '../../ui-kit/icons/arrow-down.icon';
import { CloseIcon } from '../../ui-kit/icons/close.icon';
import { StartingAnimationUI } from '../../utils/animations.utils';
import { Lazy } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';
import { CollapsedButton } from './collapsed-widget-button/collapsed-widget-button.component';
import {
	CollapsedWidgetButtonConfig,
	DismissButtonConfig,
	MinimizeButtonConfig,
	TooltipConfig,
} from './collapsed-widget.model';
import {
	useCollapsedWidgetButtonStyles,
	useCollapsedWidgetWrapperStyles,
	useFadeStyles,
	useTooltipStyles,
} from './collapsed-widget.styles';
import { Tooltip } from './tooltip/tooltip.component';

interface CollapsedWidgetProps {
	type: CollapsedWidgetType;
	isWidgetClosed: boolean;
	isWidgetOpenedOnce: boolean;
	fullScreen: boolean;
	isMinimized: boolean;
	buttonsConfig?: Nullable<CollapsedWidgetConfigResponseModel>;
	dismissButtonConfig?: DismissButtonConfig;
	minimizeButtonConfig?: MinimizeButtonConfig;
	tooltipTitle: string;
	tooltipConfig: TooltipConfig;
	tooltipAnimation?: StartingAnimationUI;
	onWidgetButtonHide: Lazy<void>;
	onClick(e: EventType, collapsedWidgetSelectedActionItem?: CollapsedWidgetButtonConfig): void;
	onMinimize: Lazy<void>;
	onCollapsedWidgetButtonsHide: Lazy<void>;
}

const INITIAL_DELAY_BEFORE_SHOW = 1400;
const TOOLTIP_MOVE_ANIMATION_TIME = 450;
let dismissButtonTimeout: NodeJS.Timeout;

export const CollapsedWidget: FC<CollapsedWidgetProps> = memo(
	({
		type,
		buttonsConfig,
		dismissButtonConfig,
		minimizeButtonConfig,
		isWidgetClosed,
		tooltipConfig,
		tooltipTitle,
		fullScreen,
		isMinimized,
		isWidgetOpenedOnce,
		onWidgetButtonHide,
		onCollapsedWidgetButtonsHide,
		onClick,
		onMinimize,
	}) => {
		const {
			palette: {
				transitionConfig: { noAnimationTransition },
			},
		} = useTheme();
		const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

		const classes = useCollapsedWidgetButtonStyles();
		const fadeClasses = useFadeStyles();
		const wrapperClasses = useCollapsedWidgetWrapperStyles();
		const tooltipStyles = useTooltipStyles({ isMobile, fullScreen });

		const [callToActions, setCallToActions] = useState<CollapsedWidgetCallActionItem[]>([]);

		const [isCollapsedButtonsVisible, setCollapsedButtonsVisible] = useState<boolean>(false);
		const [isDismissButtonVisible, setDismissButtonVisible] = useState<boolean>(false);
		const [isWidgetButtonVisible, setWidgetButtonVisible] = useState<boolean>(true);
		const [isMinimizeButtonVisible, setMinimizeButtonVisible] = useState<boolean>(false);
		const [isTooltipVisible, setTooltipVisibility] = useState<boolean>(tooltipConfig.shouldShowTooltip);
		const [isDimissAction, setDismissAction] = useState(false);

		const [isTooltipWasShown, handleTooltipShownState] = useState<boolean>(false);

		const isNoVisibleElement =
			!isCollapsedButtonsVisible && !isDismissButtonVisible && !isMinimizeButtonVisible && !isTooltipVisible;

		const ref = useRef<HTMLDivElement>(null);
		const timeout = buttonsConfig?.visibility?.durationMs || 24 * 60 * 60 * 1000;

		// TODO: refactor it
		const isSomeDismissEnabledInConfig =
			!!dismissButtonConfig && Object.values(dismissButtonConfig).some((v) => v === true);

		useEffect(() => {
			if (buttonsConfig) {
				setTimeout(() => {
					setCollapsedButtonsVisible(true);
				}, INITIAL_DELAY_BEFORE_SHOW);
			}

			setTimeout(
				() => {
					setCollapsedButtonsVisible(false);
					onCollapsedWidgetButtonsHide();
				},
				INITIAL_DELAY_BEFORE_SHOW + 2 * TOOLTIP_MOVE_ANIMATION_TIME + timeout,
			);
		}, [buttonsConfig]);

		useEffect(() => {
			if (minimizeButtonConfig) {
				setTimeout(() => {
					setMinimizeButtonVisible(true);
				}, minimizeButtonConfig?.delay);
			}
		}, [minimizeButtonConfig]);

		useEffect(() => {
			if (!isWidgetClosed && isCollapsedButtonsVisible) {
				onCollapsedWidgetButtonsHide();
			}
			if (isWidgetClosed && tooltipConfig.dismissTimeout > 0 && !tooltipConfig.keepVisible) {
				setTimeout(() => {
					setTooltipVisibility(false);
				}, tooltipConfig.dismissTimeout);
			}
		}, [isWidgetClosed]);

		useEffect(() => {
			// Hiding Dismiss button
			if (
				!isTooltipVisible &&
				!dismissButtonConfig?.dismissCollapsedWidget &&
				!dismissButtonConfig?.dismissOpenToggle
			) {
				setDismissButtonVisible(false);
			}

			if (
				!isCollapsedButtonsVisible &&
				!(dismissButtonConfig?.dismissTooltip && isTooltipVisible) &&
				!dismissButtonConfig?.dismissOpenToggle
			) {
				clearTimeout(dismissButtonTimeout);
				setDismissButtonVisible(false);
			}
			if (
				isCollapsedButtonsVisible &&
				!buttonsConfig &&
				!isTooltipVisible &&
				dismissButtonConfig?.dismissTooltip &&
				!dismissButtonConfig?.dismissOpenToggle
			) {
				setDismissButtonVisible(false);
			}
			// Showing Dismiss button
			if (
				(isTooltipVisible && dismissButtonConfig?.dismissTooltip) ||
				(isCollapsedButtonsVisible && dismissButtonConfig?.dismissCollapsedWidget) ||
				(isWidgetButtonVisible && dismissButtonConfig?.dismissOpenToggle)
			) {
				setDismissButtonVisible(true);
			}
		}, [isTooltipVisible, isCollapsedButtonsVisible, isDismissButtonVisible]);

		useEffect(() => {
			if (!isWidgetClosed) {
				handleTooltipShownState(true);
				setTooltipVisibility(false);
			}
			if (
				isWidgetClosed &&
				tooltipConfig.shouldShowTooltip &&
				(tooltipConfig.keepVisible || !isTooltipWasShown)
			) {
				setTooltipVisibility(true);
			}
		}, [isWidgetClosed]);

		const dismissButtonAppearDelay = !dismissButtonConfig?.dismissTooltip
			? tooltipConfig.dismissTimeout + 400
			: dismissButtonConfig.delay;

		useEffect(() => {
			if (isSomeDismissEnabledInConfig && !isWidgetOpenedOnce) {
				dismissButtonTimeout = setTimeout(() => {
					setDismissButtonVisible(true);
				}, dismissButtonAppearDelay);
			}
			if (isWidgetOpenedOnce) {
				setDismissButtonVisible(false);
			}
		}, [isSomeDismissEnabledInConfig, isWidgetOpenedOnce]);

		useEffect(() => {
			setCallToActions(isCollapsedButtonsVisible && buttonsConfig ? buttonsConfig.callToActions : []);
		}, [isCollapsedButtonsVisible]);

		const onDismiss = () => {
			setDismissAction(true);
			if (dismissButtonConfig?.dismissTooltip) {
				setTooltipVisibility(false);
			}
			if (dismissButtonConfig?.dismissCollapsedWidget) {
				setCollapsedButtonsVisible(false);
				onCollapsedWidgetButtonsHide();
			}
			if (dismissButtonConfig?.dismissOpenToggle) {
				setWidgetButtonVisible(false);
				onWidgetButtonHide();
			}
			setDismissButtonVisible(false);
		};

		const handleMinimize = () => {
			setCollapsedButtonsVisible(false);
			setDismissButtonVisible(false);
			onMinimize();
			onCollapsedWidgetButtonsHide();
		};

		const collapsedWidgetContainerClasses = classNames(
			tooltipStyles.collapsedWidgetContainer,
			tooltipStyles[type],
			!isWidgetClosed && tooltipStyles.hidden,
			isNoVisibleElement && wrapperClasses.noPointerEvents,
		);

		const collapsedWidgetClass = classNames(
			wrapperClasses.root,
			isCollapsedButtonsVisible && wrapperClasses.shown,
			(!isWidgetClosed || isWidgetOpenedOnce) && wrapperClasses.hidden,
		);

		const dismissButtonStyles = classNames(
			tooltipStyles.dismissButton,
			isDismissButtonVisible ? tooltipStyles.shown : tooltipStyles.hidden,
		);

		const minimizeButtonStyles = classNames(
			tooltipStyles.dismissButton,
			tooltipStyles.minimiseButton,
			isMinimizeButtonVisible ? tooltipStyles.shown : tooltipStyles.hidden,
		);

		return (
			<div className={collapsedWidgetContainerClasses} data-testing-label={'collapased-widget-container'}>
				{isWidgetClosed && minimizeButtonConfig && !isMinimized && (
					<IconButton
						color={'secondary'}
						className={minimizeButtonStyles}
						aria-label={'minimize'}
						onClick={handleMinimize}
						data-testing-label={'minimize-collapsed-widget-button'}>
						<Icon icon={ArrowDownIcon} size={'medium'} iconType={'inputIcon'} alt={'minimize'} />
					</IconButton>
				)}

				{!minimizeButtonConfig && dismissButtonConfig && !isMinimized && (
					<IconButton
						color={'secondary'}
						className={dismissButtonStyles}
						aria-label={'dismiss'}
						onClick={onDismiss}
						data-testing-label={'dismiss-collapsed-widget-button'}>
						<Icon
							icon={CloseIcon}
							size={'medium'}
							iconType={'inputIcon'}
							alt={'dismiss'}
							dataTestingLabel={'dismiss-collapsed-widget-button-icon'}
						/>
					</IconButton>
				)}

				{!isMinimized && (
					<Fragment>
						<CSSTransition
							in={isTooltipVisible}
							mountOnEnter
							unmountOnExit
							addEndListener={(node, done) => {
								node.addEventListener('transitionend', done, false);
							}}
							classNames={fadeClasses}
							timeout={noAnimationTransition ? 0 : undefined}>
							<div className={tooltipStyles.widthAuto}>
								<Tooltip
									tooltipTitle={tooltipTitle}
									className={tooltipStyles.tooltip}
									onClick={onClick}
								/>
							</div>
						</CSSTransition>
						{buttonsConfig && (
							<div
								ref={ref}
								className={collapsedWidgetClass}
								data-testing-label={'collapsed-widget-buttons-container'}>
								<TransitionGroup component={null}>
									{callToActions.map((callToAction, index) => (
										<CSSTransition
											key={index}
											classNames={{
												enter: classes.transitionEnter,
												enterActive: classes.transitionEnterActive,
												exit: classes.transitionExit,
												exitActive: classes.transitionExitActive,
											}}
											timeout={timeout}>
											<CollapsedButton
												data-testing-label={'collapsed-widget-button'}
												tabIndex={isCollapsedButtonsVisible ? 0 : -1}
												key={callToAction.renderedTitle}
												index={index}
												callToAction={callToAction}
												transition={`opacity 0.65s ease ${
													isDimissAction ? 0 : TOOLTIP_MOVE_ANIMATION_TIME + index * 150
												}ms, top 0.35s ease ${TOOLTIP_MOVE_ANIMATION_TIME + index * 150}ms`}
												onClick={(e: EventType) =>
													onClick(e, {
														...callToAction,
														eventCallbackData: {
															eventName: 'onUserClickCollapsedWidget',
															data: {
																collapsedWidgetConfig: buttonsConfig,
																clickIndex: index,
															},
														},
														startingFlow: buttonsConfig?.startingFlow,
													})
												}
											/>
										</CSSTransition>
									))}
								</TransitionGroup>
							</div>
						)}
					</Fragment>
				)}
			</div>
		);
	},
);
