import queryString from 'query-string';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';

import { APIServiceContext } from '../../../context/api-service/api-service.context';
import { ConfigContext } from '../../../context/config.context';
import { SessionContext } from '../../../context/session.context';
import { AppConfig, ExternalSurveyDataProps } from '../../../models/app.model';
import { Theme } from '../../../ui-kit/theme/theme.component';
import { HelpfulnessSurveyExternal } from '../../../ui-kit/user-survey/helpfulness-survey-external/helpfulness-survey-external.component';
import { getClientCookieName, setCookie } from '../../../utils/cookies.utils';
import i18n from '../../../utils/i18.utils';
import { mapAppConfigResponseToAppConfig } from '../../app/app.model';
import {
	getSavedSurvey,
	sendFeedbackSurvey,
	setSavedSurvey,
	SURVEY_EXTERNAL_TIMEOUT,
	THUMBS_ANIMATION_TIME,
} from '../helfulness-survey.model';

interface HelpfulnessSurveyContainerProps {
	mode: 'integrated' | 'standalone';
	externalSurveyData?: string;
}

const extractJsonFromSurveyData = (externalData: string): ExternalSurveyDataProps | undefined => {
	try {
		const dataJSON = JSON.parse(atob(externalData));
		return Object.keys(dataJSON).length !== 0 ? dataJSON : undefined;
	} catch (e) {
		return undefined;
	}
};

const getExternalSurveyDataParams = (): string => {
	const parsedParameters = queryString.parse(window.location.search, {
		parseBooleans: true,
		parseNumbers: true,
	});

	const params = Object.keys(parsedParameters)
		.filter((val) => val === 'externalSurveyData')
		.reduce(
			(obj, key) => {
				obj[key] = parsedParameters[key];
				return obj;
			},
			{ externalSurveyData: '' },
		);

	return params.externalSurveyData;
};

export const HelpfulnessSurveyExternalContainer: FC<HelpfulnessSurveyContainerProps> = memo(
	({ mode, externalSurveyData }) => {
		const [isOpen, setIsOpen] = useState(false);
		const [shouldRemoveSurvey, setShouldRemoveSurvey] = useState(false);
		const [appConfig, setAppConfig] = useState<AppConfig>();
		const { colorScheme, visualConfig, settings } = appConfig || {};

		const {
			state: { sessionToken },
		} = useContext(SessionContext);

		const { sendEvent, sendAnonymousEvent, getConfig } = useContext(APIServiceContext);
		const {
			appConfig: {
				settings: { cookies },
			},
		} = useContext(ConfigContext);

		const externalSurveyDataValue = extractJsonFromSurveyData(externalSurveyData || getExternalSurveyDataParams());
		if (!externalSurveyDataValue) {
			console.error('Missing data in externalSurveyData');
			return null;
		}

		useEffect(() => {
			if (mode === 'standalone') {
				getConfig(externalSurveyDataValue.client).then((config) => {
					if (config && config.active) {
						const parsedAppConfig = mapAppConfigResponseToAppConfig(config);
						setAppConfig(parsedAppConfig);
						setCookie(
							getClientCookieName(externalSurveyDataValue.client),
							externalSurveyDataValue.sessionToken,
							config.settings?.cookies?.expireTimeout || 0,
						);
					}
				});
			}
			if (!sessionToken && mode === 'integrated') {
				setCookie(
					getClientCookieName(externalSurveyDataValue.client),
					externalSurveyDataValue.sessionToken,
					cookies?.expireTimeout || 0,
				);
			}
			setTimeout(() => {
				setIsOpen(true);
			}, 700);
		}, []);

		const sendFeedbackWrapper = (rating: number): void => {
			sendFeedbackSurvey(
				'HELPFULNESS_SURVEY_EXTERNAL',
				rating,
				externalSurveyDataValue.flowStep,
				externalSurveyDataValue.client,
				externalSurveyDataValue.sessionToken,
				sendEvent,
				sendAnonymousEvent,
			);

			setTimeout(() => {
				setIsOpen(false);
				setSavedSurvey(externalSurveyDataValue.client, rating);
			}, THUMBS_ANIMATION_TIME);
			setTimeout(
				() => {
					setShouldRemoveSurvey(true);
				},
				THUMBS_ANIMATION_TIME + SURVEY_EXTERNAL_TIMEOUT * 3,
			);
		};

		const onCloseSurveyWrapper = () => {
			setSavedSurvey(externalSurveyDataValue.client, 0);
			setIsOpen(false);
			setTimeout(() => setShouldRemoveSurvey(true), THUMBS_ANIMATION_TIME * 2);
		};

		const renderIntegratedHelpfulnessSurveyExternal = () => (
			<HelpfulnessSurveyExternal
				isOpen={isOpen}
				action={sendFeedbackWrapper}
				onCloseSurvey={onCloseSurveyWrapper}
				hasAvatarButton={mode === 'standalone'}
			/>
		);

		const savedSurvey = getSavedSurvey(externalSurveyDataValue.client);
		const isValidStandaloneSurveyData = !shouldRemoveSurvey && colorScheme && visualConfig && !savedSurvey;
		const renderStandaloneHelpfulnessSurveyExternal = () => {
			if (mode === 'standalone') {
				if (isValidStandaloneSurveyData) {
					return (
						<Theme
							colorScheme={colorScheme}
							visualConfig={visualConfig}
							themeConfig={settings?.themeConfig}
							isGlobalStyles={false}>
							<I18nextProvider i18n={i18n}>{renderIntegratedHelpfulnessSurveyExternal()}</I18nextProvider>
						</Theme>
					);
				}
			} else {
				if (!shouldRemoveSurvey) {
					return renderIntegratedHelpfulnessSurveyExternal();
				}
			}

			return null;
		};

		return renderStandaloneHelpfulnessSurveyExternal();
	},
);
