import {
	CustomStyle,
	MenuItemActionModel,
	MenuTextModel,
	StartingAnimation,
	ThemeConfig,
} from '../context/api-service/api-service.model';
import { constVoid, Effect } from '../utils/function.utils';
import { Language, LanguageNameLowerCase, LocaleLocalization } from './languages.model';
import { DEFAULT_WEB_TRACKER_CONFIG, WebTrackerConfig } from './trackers.model';

export type EnvironmentType = 'local' | 'development' | 'staging' | 'production' | 'recovery';

// same soultion as in widget-sass.template
export const IMAGES_BASE_PATH_GENERAL = 'https://s3.amazonaws.com/assets.gyant.com/widget/frontdoor-default/';

export const DEFAULT_BRAND_COLOR = 'hsla(203, 100%, 11%, 1)';
export const DEFAULT_PRIMARY_COLOR = 'hsla(203, 100%, 11%, 1)';

export type CollapsedWidgetType = 'short' | 'tall';

export interface ClientEvent {
	onBotMessage: Effect<string>;
	onWidgetVisibilityChange: Effect<boolean>;
	onWidgetLoad: Effect<Document>;
}

export interface PatientInfo {
	patientId: string;
	name: string;
	gender: string;
	age: number;
	sessionToken: string;
	triageEnabled: boolean;
	patientIdType: string;
	patientSource: string;
}

export interface ExternalSurveyDataProps {
	flowStep: string;
	sessionToken: string;
	client: string;
}

export interface AppOptions {
	client: string;
	org: string;
	fullScreen: boolean;
	gyAutoOpen: boolean;
	gyStFl: string;
	language?: LanguageNameLowerCase;
	disableTextInput: boolean;
	enableUndo: boolean;
	hideCloseBtn: boolean;
	noIframe: boolean;
	initTimestamp: number;
	sessionToken: string;
	gyTesting: boolean;
	gyDebugging: boolean;
	events: ClientEvent;
	noAnimation: boolean;
	patientInfo?: Partial<PatientInfo>;
	externalSurveyData?: string;
	disableWhenSessionExpired?: boolean;
}

export type InitialAppOptionsKeys = keyof AppOptions;

const DEFAULT_CLIENT_EVENTS: ClientEvent = {
	onBotMessage: constVoid,
	onWidgetVisibilityChange: constVoid,
	onWidgetLoad: constVoid,
};

export const DEFAULT_APP_OPTIONS: AppOptions = {
	fullScreen: true,
	gyAutoOpen: false,
	enableUndo: true,
	disableTextInput: false,
	hideCloseBtn: false,
	client: 'gy-fd',
	org: 'gy-fd',
	gyStFl: '',
	events: DEFAULT_CLIENT_EVENTS,
	gyTesting: false,
	gyDebugging: false,
	sessionToken: '',
	noIframe: false,
	noAnimation: false,
	initTimestamp: new Date().getTime(),
	externalSurveyData: '',
	disableWhenSessionExpired: false,
};

export interface AutoOpen {
	afterMs: number; // 5000
	device: 'DESKTOP' | 'MOBILE' | 'NONE';
}

export interface CookiesSettings {
	expireTimeout: number;
	removeCookiesOnCollapse: boolean;
	enabled: boolean;
	disableWidgetOnCookieExpire: boolean;
}
export interface MenuTextLocalization {
	menu: MenuTextModel;
	locale: LocaleLocalization;
}

export interface TooltipLocalization {
	locale: LocaleLocalization;
	tooltip: string;
}

export interface TooltipSettings {
	tooltip: string;
	keepTooltipVisible: boolean;
	showTooltipOncePerSession: boolean;
	localizations: TooltipLocalization[];
	tooltipVisibleTimeMs: number;
}

export interface Settings {
	autoOpen: AutoOpen;
	cookies: CookiesSettings;
	defaultLanguage: LocaleLocalization;
	addFullScreenCloseBtn: boolean;
	confirmOpenLink: boolean;
	linkTarget?: string;
	maxMessageDelayMs: number; // 2000;
	progressBar: boolean;
	tooltipSetting: TooltipSettings;
	useBrowserLanguage: boolean;
	postMessageToParent: boolean;
	preLoadFirstMessage: boolean;
	menuTextSetting: MenuTextLocalization[];
	themeConfig: ThemeConfig;
}

export type ChipsType = 'stacked' | 'contained';

export interface VisualConfig {
	defaultFontSize: number;
	radiusType: string;
	widgetBackground: string;
	animateBackground: boolean;
	collapsedWidgetType: CollapsedWidgetType;
	headerAvatar?: string;
	buttonAvatar?: string;
	assistantName?: string;
	chipsType: ChipsType;
	commonCarouselResponseFlows: string[];
}

// TODO: integrate postMessageToParent
export const DEFAULT_SETTINGS: Settings = {
	autoOpen: {
		afterMs: 5000,
		device: 'NONE',
	},
	cookies: {
		expireTimeout: 86400,
		removeCookiesOnCollapse: false,
		enabled: true,
		disableWidgetOnCookieExpire: false,
	},
	defaultLanguage: 'en',
	addFullScreenCloseBtn: false,
	confirmOpenLink: false,
	maxMessageDelayMs: 2000,
	progressBar: false,
	tooltipSetting: {
		tooltip: '',
		keepTooltipVisible: false,
		showTooltipOncePerSession: false,
		localizations: [],
		tooltipVisibleTimeMs: 5500,
	},
	menuTextSetting: [
		{
			locale: 'en',
			menu: {
				title: 'Hello',
				text: 'I’m an automated assistant. I’m here to help you find what you need.',
			},
		},
	],
	useBrowserLanguage: false,
	postMessageToParent: false,
	preLoadFirstMessage: false,
	themeConfig: { version: 'fabric.v1' },
};

export const DEFAULT_VISUAL_CONFIG: VisualConfig = {
	defaultFontSize: 17,
	radiusType: 'Radius_3',
	widgetBackground: 'white',
	animateBackground: false,
	collapsedWidgetType: 'tall',
	chipsType: 'contained',
	commonCarouselResponseFlows: [],
};

export interface ColorScheme {
	lightest: string;
	light: string;
	mediumLight: string;
	lightBrandBackground: string;
	mediumDark: string;
	dark: string;
	darkest: string;
	logoBG: string;
	collapsedChatButtonImageUrl: string;
	logoBGWidth: string;
	logoBGHeight: string;
	mobileLogoBgWidth: string;
	mobileLogoBgHeight: string;
	botAvatar?: string;
	chatWindowHeight: string;
	chatWindowBottomPosition: string;
	chatWindowRightPosition: string;
	providerBubbleColor: string;
	providerFontColor: string;
	fontFamilyRegular: string;
	fontFamilyMedium: string;
	mobileHeaderHeight: string;
	mobileHeaderLogoWidth: string;
	mobileHeaderLogoHeight: string;
	customStyle: Partial<CustomStyle>;
	customSassCode?: string;
	// new colors
	colorVariables: ColorVariables;
}

export interface ColorVariables {
	brandColor: string;
	colorPrimary: string;
	colorPrimarySoft: string;
	colorHead: string;
	colorText: string;
	colorMediumShadow: string;
	colorLightShadow: string;
	colorLightShadowNoOpacity: string;
	colorDarkShadow: string;
	colorBorder: string;
	colorCaption: string;
	colorNote: string;
	colorInactive: string;
	colorHeadOnDark: string;
	colorTextOnDark: string;
	colorBgBody: string;
	colorBgOverlay: string;
	colorBgSurface: string;
	colorSysTextDefault: string;
	colorSysDefault: string;
	colorSysBgDefault: string;
	colorSysTextAlert: string;
	colorSysAlert: string;
	colorSysBgAlert: string;
	colorSysTextWarning: string;
	colorSysWarning: string;
	colorSysBgWarning: string;
	colorSysTextSuccess: string;
	colorSysSuccess: string;
	colorSysBgSuccess: string;
	colorSysTextInfo: string;
	colorSysInfo: string;
	colorSysBgInfo: string;
	colorSysTextOff: string;
	colorSysOff: string;
	colorSysBgOff: string;
}

export const DEFAULT_CUSTOM_STYLE: CustomStyle = {
	botMessages: {},
	iframe: {
		fullScreen: {},
		notFullScreen: {},
	},
	userMessage: {},
	collapsedChatButton: {},
	openedChatButton: {},
	quickResponseButtons: {
		main: {},
		external: {},
	},
	multiselect: {
		main: {},
		item: {},
		sendButton: {},
	},
	footer: {},
	userInput: {},
	sendButton: {},
	tooltip: {
		container: {},
		component: {},
	},
	global: {},
	carousel: {
		title: {},
		responseButton: {},
	},
	mainChat: {},
	content: {},
	header: {
		main: {},
		logo: {},
		closeButton: {},
	},
	progressBar: {},
	poweredBy: {},
	menu: {},
};

export const DEFAULT_COLOR_VARIABLES: ColorVariables = {
	brandColor: 'hsla(203, 100%, 11%, 1)',
	colorPrimary: 'hsla(203, 100%, 11%, 1)',
	colorPrimarySoft: 'hsla(204, 35%, 96%, 1)',
	colorHead: 'hsla(0, 0%, 3%, 1)',
	colorText: 'hsla(0, 0%, 3%, 1)',
	colorMediumShadow: 'hsla(203, 100%, 10%, 0.08)',
	colorDarkShadow: 'hsla(203, 100%, 10%, 0.14)',
	colorLightShadow: 'hsla(203, 100%, 10%, 0.02)',
	colorBorder: 'hsla(0, 0%, 73%, 1)',
	colorCaption: 'hsla(0, 0%, 53%, 1)',
	colorNote: 'hsla(0, 0%, 53%, 1)',
	colorInactive: 'hsla(0, 0%, 97%, 1)',
	colorHeadOnDark: 'hsla(0, 0%, 100%, 1)',
	colorTextOnDark: 'hsla(0, 0%, 100%, 0.8)',
	colorBgBody: 'hsla(0, 0%, 100%, 1)',
	colorBgOverlay: 'hsla(0, 0%, 100%, 0.96)',
	colorBgSurface: 'hsla(0, 0%, 100%, 1)',
	colorSysTextDefault: 'hsla(0, 0%, 53%, 1)',
	colorSysDefault: 'hsla(0, 0%, 53%, 1)',
	colorSysBgDefault: 'hsla(0, 0%, 98%, 1)',
	colorSysTextAlert: 'hsla(0, 74%, 42%, 1)',
	colorSysAlert: 'hsla(0, 72%, 51%, 1)',
	colorSysBgAlert: 'hsla(0, 86%, 97%, 1)',
	colorSysTextWarning: 'hsla(32, 81%, 29%, 1)',
	colorSysWarning: 'hsla(45, 93%, 47%, 1)',
	colorSysBgWarning: 'hsla(60, 77%, 95%, 1)',
	colorSysTextSuccess: 'hsla(142, 72%, 29%, 1)',
	colorSysSuccess: 'hsla(142, 76%, 36%, 1)',
	colorSysBgSuccess: 'hsla(138, 76%, 97%, 1)',
	colorSysTextInfo: 'hsla(226, 71%, 40%, 1)',
	colorSysInfo: 'hsla(221, 83%, 53%, 1)',
	colorSysBgInfo: 'hsla(216, 100%, 97%, 1)',
	colorSysTextOff: 'hsla(0, 0%, 97%, 1)',
	colorSysOff: 'hsla(0, 0%, 81%, 1)',
	colorSysBgOff: 'hsla(0, 0%, 32%, 1)',
	colorLightShadowNoOpacity: '#F0F0F1',
};

export const DEFAULT_COLOR_SCHEME: ColorScheme = {
	lightest: '#FFFFFF',
	light: '#EFEFEF',
	mediumLight: '#2676AF',
	lightBrandBackground: '#FFFFFF',
	mediumDark: '#2676AF',
	dark: '#183D59',
	darkest: '#000000',
	logoBG: 'https://s3.amazonaws.com/assets.gyant.com/widget/client/gy-fd/logo-gy-fd.svg',
	collapsedChatButtonImageUrl: 'https://s3.amazonaws.com/assets.gyant.com/widget/client/gy-fd/icon-chat-expand.svg',
	logoBGWidth: '132px',
	logoBGHeight: '28px',
	mobileLogoBgWidth: '90px',
	mobileLogoBgHeight: '19px',
	botAvatar: 'https://s3.amazonaws.com/assets.gyant.com/widget/client/gy-fd/avatar-gy-fd.svg',
	chatWindowHeight: '80vh',
	chatWindowBottomPosition: '104px',
	chatWindowRightPosition: '16px',
	providerBubbleColor: '#ffffff',
	providerFontColor: '#000000',
	fontFamilyRegular:
		"'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
	fontFamilyMedium:
		"'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
	mobileHeaderHeight: '50px',
	mobileHeaderLogoWidth: '120px',
	mobileHeaderLogoHeight: '30px',
	customStyle: {},
	colorVariables: DEFAULT_COLOR_VARIABLES,
};

export interface MenuItem {
	locale: LocaleLocalization;
	menuItemActions: MenuItemActionModel[];
}

export interface AppConfig {
	animations: StartingAnimation[];
	languages: Language[];
	webTracker: WebTrackerConfig;
	menu: MenuItem[];
	settings: Settings;
	colorScheme: ColorScheme;
	visualConfig: VisualConfig;
	documentTitle: string;
}

export const DEFAULT_APP_CONFIG: AppConfig = {
	menu: [],
	languages: [],
	animations: [],
	settings: DEFAULT_SETTINGS,
	webTracker: DEFAULT_WEB_TRACKER_CONFIG,
	colorScheme: DEFAULT_COLOR_SCHEME,
	visualConfig: DEFAULT_VISUAL_CONFIG,
	documentTitle: '',
};
