import { makeStyles, Theme } from '@material-ui/core';

export const useMenuOptionsStyles = makeStyles<Theme>(
	({
		palette: {
			sizes,
			boxShadow,
			colorScheme: {
				colorVariables: { colorLightShadow, colorPrimarySoft },
			},
		},
		typography: { smallViewPort },
	}) => ({
		paper: {
			...smallViewPort.body,
			width: 264,
			border: `1px solid ${colorLightShadow}`,
			boxShadow: boxShadow.angle.xlarge,
			transform: `translateX(${sizes.size_3}px) !important`,
			borderRadius: sizes.size_2,
			margin: `-${sizes.sizeHalf}px 0px`,
			background: '#FFFFFF',
			'& ul': {
				padding: 0,
				'& li': {
					height: sizes.size_6,
					'&:hover': {
						background: colorPrimarySoft,
					},
				},
			},
		},
		item: {
			width: '100%',
			overflow: 'hidden',
			marginRight: sizes.size_1,
			textOverflow: 'ellipsis',
		},
		heightWrapper: {
			height: sizes.size_6,
		},
	}),
);
